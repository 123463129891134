import {
  Breadcrumbs,
  BrowserClient,
  BrowserTracing,
  Dedupe,
  FunctionToString,
  GlobalHandlers,
  HttpContext,
  LinkedErrors,
  TryCatch,
  defaultStackParser,
  getCurrentHub,
  makeFetchTransport
} from '@sentry/browser';

import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';

const environment =
  process.env.GATSBY_SENTRY_ENVIRONMENT ||
  process.env.CF_PAGES_BRANCH ||
  'production';

const allowedUrl = process.env.GATSBY_HOST;
if (!allowedUrl) {
  throw Error('GATSBY_HOST missing');
}

async function isBot() {
  const isbot = await import(/* webpackChunkName: "isbot" */ 'isbot').then(
    x => x.default
  );
  return isbot(navigator.userAgent);
}

export function initSentry() {
  const client = new BrowserClient({
    allowUrls: [allowedUrl as string],
    dsn: process.env.GATSBY_SENTRY_DSN,
    tunnel: process.env.GATSBY_SENTRY_TUNNEL,
    enabled: process.env.GATSBY_SENTRY_DISABLED !== 'true',
    environment,
    release: process.env.CF_PAGES_COMMIT_SHA,
    tracesSampleRate: environment === 'local' ? 1.0 : 0.5,

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async beforeSend(event, _hint) {
      if (await isBot()) {
        return null;
      }

      // TODO: showReportDialog downloads script from sentry instance
      // with url derived from dsn, which wouldn't work as it's internal
      // const state = getState();
      // const user = state.userProfile.data?.user;
      // // Check if it is an exception, and if so, show the report dialog
      // if (event.exception) {
      //   showReportDialog({
      //     eventId: event.event_id,
      //     user: {
      //       email: user?.email,
      //       name: user?.firstName
      //     }
      //   });
      // }

      return event;
    },

    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    integrations: [
      new ExtraErrorDataIntegration({ depth: 10 }),
      new Breadcrumbs(),
      new BrowserTracing(),
      new Dedupe(),
      new FunctionToString(),
      new GlobalHandlers(),
      new HttpContext(),
      new LinkedErrors(),
      new TryCatch()
    ]
  });

  getCurrentHub().bindClient(client);
}
